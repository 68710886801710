import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "core/presentation/store";
import { orderModuleContainer } from "order/OrderModule";
import OrderUseCase from "order/application/OrderUseCase";
import { date } from "yup";

const initialState: any = {
  orderDetail: [],
  orders: [],
  shipperBids: [],
  digitalContract: "",
  canceledBids: [],
  newRequest: [],
  newRequestCount: 0,
  acceptedRequest: [],
  acceptedRequestCount: 0,
  ordersCount: 0,
  orderandRequest: [],
  orderandRequestCount: 0,
  bidList: [],
  canceledBidsCount: 0,
};

export const getUnits = async () => {
  const useCase = orderModuleContainer.getProvided(OrderUseCase);
  try {
    const data = await useCase.getUnits();
    return data.data;
  } catch (error) {
    return error;
  }
};

export const getVehicleTypes = async () => {
  const useCase = orderModuleContainer.getProvided(OrderUseCase);
  try {
    const data = await useCase.getVehicleTypes();
    return data.data;
  } catch (error) {
    return error;
  }
};

const putOrderSubmitThunk = async (values: any, orderId: any) => {
  const useCase = orderModuleContainer.getProvided(OrderUseCase);
  try {
    const response = await useCase.putSubmitOrder(values, orderId);
    return response;
  } catch (error) {
    return error;
  }
};

const getAllPickupLocations = async () => {
  const useCase = orderModuleContainer.getProvided(OrderUseCase);
  try {
    const data = await useCase.getAllPickupLocations();
    return data;
  } catch (error) {
    return error;
  }
};

const getAllDeliveryLocations = async () => {
  const useCase = orderModuleContainer.getProvided(OrderUseCase);
  try {
    const data = await useCase.getAllDeliveryLocations();
    return data;
  } catch (error) {
    return error;
  }
};

const placeOrderThunk = async (values: any, action?: string) => {
  const useCase = orderModuleContainer.getProvided(OrderUseCase);
  try {
    const data = await useCase.placeOrder(values, action);
    return data;
  } catch (error) {
    return error;
  }
};

export const getOrderDetailThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>(
  "users/order-details",
  async ({ id, deletePrev }: { id: any; deletePrev?: boolean }) => {
    const useCase = orderModuleContainer.getProvided(OrderUseCase);
    const response = await useCase.getOrderDetail(id, deletePrev);
    return response;
  }
);

export const orderCancelShipperThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("users/order-cancellation", async (values) => {
  const useCase = orderModuleContainer.getProvided(OrderUseCase);

  const response = await useCase.orderCancelShipper(
    values.vehicleSpecificationId,
    values.values
  );
  return response;
});

export const getBidRequestsThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("users/bid-requests", async (id: any) => {
  const useCase = orderModuleContainer.getProvided(OrderUseCase);
  const response = await useCase.getBidRequests(id);
  return response;
});

export const updateOrderThunk = createAsyncThunk<
  any,
  { orderId: any; values: any },
  { state: RootState }
>("users/update-order", async ({ orderId, values }) => {
  const useCase = orderModuleContainer.getProvided(OrderUseCase);
  const response = await useCase.updateOrder(orderId, values);
  return response.data;
});

export const getAllOrdersThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>(
  "users/orders",
  async ({
    status,
    page,
    input,
    filter,
  }: {
    status?: string;
    page?: number;
    input?: string;
    filter: string;
  }) => {
    const useCase = orderModuleContainer.getProvided(OrderUseCase);
    const response = await useCase.getAllOrders(status, page, input, filter);
    return response;
  }
);

export const getPaidBidCancelThunk = createAsyncThunk<
  any,
  { page: number; input: string; filter: string },
  { state: RootState }
>("users/order-bid-cancel", async ({ page, input, filter }) => {
  const useCase = orderModuleContainer.getProvided(OrderUseCase);
  const response = await useCase.getPaidBidCancel(page, input, filter);
  return response;
});

export const rebidThunk = createAsyncThunk<any, any, { state: RootState }>(
  "users/rebid",
  async (values: any) => {
    const useCase = orderModuleContainer.getProvided(OrderUseCase);
    const response = await useCase.rebid(values);
    return response;
  }
);

// const rebidThunk = async (values: any) => {
//   const useCase = orderModuleContainer.getProvided(OrderUseCase);
//   try {
//     const data = await useCase.rebid(values);
//     return data;
//   } catch (error) {
//     return error;
//   }
// };

export const getBidHistoryThunk = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("users/bid-history", async () => {
  const useCase = orderModuleContainer.getProvided(OrderUseCase);
  const response = await useCase.getBidHistory();
  return response;
});

export const getShipperBidsThunk = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("order/getShipperBids", async () => {
  const useCase = orderModuleContainer.getProvided(OrderUseCase);
  const response = useCase.getShipperBids();
  return response;
});

export const shipperAcceptBidsThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("order/shipperAcceptBids", async (bidId: number) => {
  const useCase = orderModuleContainer.getProvided(OrderUseCase);
  const response = useCase.shipperAcceptBids(bidId);
  return response;
});

export const getCoordsThunk = createAsyncThunk<any, any, { state: RootState }>(
  "order/getCoords",
  async (values: any) => {
    const useCase = orderModuleContainer.getProvided(OrderUseCase);
    const response = useCase.getCoords(values);
    return response;
  }
);

export const getDigitalContractWithBidRequest = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("order/getDigitalContractWithBidRequest", async (id: any) => {
  const useCase = orderModuleContainer.getProvided(OrderUseCase);
  const response = await useCase.getDigitalContractWithBidRequest(id);
  return response;
});

const acknowledgeDigitalContractThunk = async (values: any) => {
  const useCase = orderModuleContainer.getProvided(OrderUseCase);
  try {
    const data = await useCase.acknowledgeDigitalContract(values);
    return data;
  } catch (error) {
    return error;
  }
};

export const updateDraftOrder = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("order/updateDraftOrder", async ({ orderId, values }) => {
  const useCase = orderModuleContainer.getProvided(OrderUseCase);
  const response = await useCase.updateDraftOrder(orderId, values);
  return response;
});

export const cancelRequestShipperThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("order/updateDraftOrder", async ({ orderNumber, values }) => {
  const useCase = orderModuleContainer.getProvided(OrderUseCase);
  const response = await useCase.cancelRequestShipper(orderNumber, values);
  return response;
});

export const getNewRequestThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("orders/getNewRequestThunk", async ({ page }: { page?: number }) => {
  const useCase = orderModuleContainer.getProvided(OrderUseCase);
  const response = await useCase.getNewRequest(page);
  return response;
});

export const getAcceptedRequestThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("orders/getAcceptedRequestThunk", async ({ page }: { page?: number }) => {
  const useCase = orderModuleContainer.getProvided(OrderUseCase);
  const response = await useCase.getAcceptedRequest(page);
  return response;
});

//request and order

export const getRequestAndOrderThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>(
  "users/requestandorders",
  async ({
    status,
    page,
    input,
    filter,
  }: {
    status?: string;
    page: number;
    input: string;
    filter: string;
  }) => {
    const useCase = orderModuleContainer.getProvided(OrderUseCase);
    const response = await useCase.getRequestAndOrder(
      status,
      page,
      input,
      filter
    );
    if (page == 0) {
      return { data: response, skipStateUpdate: true };
    }
    return response;
  }
);

export const getBidListThunk = createAsyncThunk<any, any, { state: RootState }>(
  "users/getBidListThunk",
  async ({ vehicleSpecId }: { vehicleSpecId: number }) => {
    const useCase = orderModuleContainer.getProvided(OrderUseCase);
    const response = await useCase.getBidList(vehicleSpecId);
    return response;
  }
);

//cancel the order
const cancelOrderThunk = async (orderId: any) => {
  const useCase = orderModuleContainer.getProvided(OrderUseCase);
  try {
    const response = await useCase.cancelOrder(orderId);
    return response;
  } catch (error) {
    return error;
  }
};

const extendExpiryThunk = async (id: number) => {
  const useCase = orderModuleContainer.getProvided(OrderUseCase);
  try {
    const response = await useCase.extendExpiry(id);
    return response;
  } catch (error) {
    return error;
  }
};
export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getOrderDetailThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.orderDetail = action?.payload?.data?.data;
      })
      .addCase(getOrderDetailThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      });

    builder
      .addCase(getAllOrdersThunk.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllOrdersThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.orders = action?.payload?.data?.data?.orders;
        state.ordersCount = action?.payload?.data?.data?.count;
      })
      .addCase(getAllOrdersThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(getPaidBidCancelThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.canceledBids = action?.payload?.data?.data;
        state.canceledBidsCount = action?.payload?.data?.data?.count;
      })
      .addCase(getPaidBidCancelThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(rebidThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.canceledBids = action?.payload?.data?.data;
      })
      .addCase(rebidThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      });
    builder.addCase(getShipperBidsThunk.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.shipperBids = action.payload.data;
    });
    builder
      .addCase(getDigitalContractWithBidRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.digitalContract = action?.payload?.data?.data;
      })
      .addCase(getDigitalContractWithBidRequest.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      });
    builder
      .addCase(getNewRequestThunk.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getNewRequestThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.newRequestCount = action?.payload?.data?.data?.count;

        state.newRequest = action?.payload?.data?.data?.requests;
      })
      .addCase(getNewRequestThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      });

    builder
      .addCase(getAcceptedRequestThunk.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAcceptedRequestThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.acceptedRequestCount = action?.payload?.data?.data?.count;

        state.acceptedRequest = action?.payload?.data?.data?.requests;
      })
      .addCase(getAcceptedRequestThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      });

    builder
      .addCase(getRequestAndOrderThunk.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getRequestAndOrderThunk.fulfilled, (state, action) => {
        state.error = null;
        if (action.payload.skipStateUpdate) {
          return state; // skip state update
        }
        // Update the global state for page > 0
        //change the data structure
        state.orderandRequest = action?.payload?.data?.data?.requests;
        state.orderandRequestCount = action?.payload?.data?.data?.count;
        state.isLoading = false;
      })
      .addCase(getRequestAndOrderThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      });

    builder
      .addCase(getBidListThunk.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getBidListThunk.fulfilled, (state, action) => {
        state.bidList = action?.payload?.data;
        state.isLoading = false;
      })
      .addCase(getBidListThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      });
  },
});

export const userSelectors = {};

export const orderActions = orderSlice.actions;

export const orderThunks = {
  getUnits,
  getVehicleTypes,
  getAllPickupLocations,
  getAllDeliveryLocations,
  getAllOrdersThunk,
  placeOrderThunk,
  getOrderDetailThunk,
  updateOrderThunk,
  getShipperBidsThunk,
  shipperAcceptBidsThunk,
  getCoordsThunk,
  getDigitalContractWithBidRequest,
  acknowledgeDigitalContractThunk,
  getBidHistoryThunk,
  putOrderSubmitThunk,
  getBidRequestsThunk,
  orderCancelShipperThunk,
  getPaidBidCancelThunk,
  rebidThunk,
  updateDraftOrder,
  cancelRequestShipperThunk,
  getNewRequestThunk,
  getAcceptedRequestThunk,
  getRequestAndOrderThunk,
  getBidListThunk,
  cancelOrderThunk,
  extendExpiryThunk,
};

export const orderReducer = orderSlice.reducer;
