import React, { useEffect, useState } from "react";
import ATitle from "core/presentation/components/Title";
import { PendingNotificationIcon } from "../../../assets/icons/PendingNotificationIcon";
import { color } from "theme/Color";
import AText from "core/presentation/components/Text";
import ShipperNotifications from "../components/Notifications/ShipperNotifications";
import { useAppDispatch, useAppSelector } from "core/presentation/store/hooks";
import { notificationThunks } from "../store/notificationSlice";
import { NotificationType } from "../store/types";
import { useNavigate } from "react-router-dom";
import AButton from "core/presentation/components/Button";

const Notifications = ({
  notificationVisible,
  setNotificationVisible,
}: any) => {
  const dispatch = useAppDispatch();
  const {
    notifications,
    notificationsCount,
    notificationsHasNextPage,
    isLoading,
  } = useAppSelector((state) => state?.notification);
  const navigate = useNavigate();

  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(notificationThunks.getNotificationThunk({ page: page }));
  }, []);
  const [hoveredNotification, setHoveredNotification] = useState<number | null>(
    null
  );

  const handleLoadMore = () => {
    if (notificationsHasNextPage) {
      setPage((prevPage) => prevPage + 1);
    }
    dispatch(notificationThunks.getNotificationThunk({ page: page }));
  };

  const handlePress = async (notification: any) => {
    await dispatch(
      notificationThunks.seenNotificationThunk({
        id: notification?.notificationId,
      })
    );

    if (notification?.type === "driver") {
      if (notification?.page === "Driver" && !!notification?.param) {
        navigate(`/driver/details/${notification?.param}`);
      } else {
        navigate("/driver");
      }
    } else if (notification?.type === "order") {
      if (notification?.page === "Trip" && !!notification?.param) {
        navigate(`/trip/details/${notification?.param}`);
      } else {
        // navigate("/orders");
        navigate(`/orders/?tab=${notification.event}`);
      }
    } else if (notification?.type === "trip") {
      if (notification?.page === "Trip" && !!notification?.param) {
        navigate(`/trip/details/${notification?.param}`);
      } else {
        navigate("/trip");
      }
    } else if (notification?.type === "vehicle") {
      navigate("/vehicle");
    } else if (
      notification?.type === "other" &&
      notification?.page === "Vehicle" &&
      !!notification?.param
    ) {
      navigate(`/vehicle/details/${notification?.param}`);
    }
    setNotificationVisible(false);
  };
  const ReadAll = async () => {
    await dispatch(notificationThunks.seenAllNotificationThunk());
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #ECECEC",
          paddingBottom: "10px",
        }}
      >
        <ATitle
          content="Notifications"
          fontSize={24}
          fontWeight={700}
          marginTop="0"
          textColor={color.DARK}
        />
        <button
          onClick={ReadAll}
          style={{
            backgroundColor: "transparent",
            border: "none",
            color: "#F26533",
            cursor: "pointer",
            fontSize: "18px",
            fontWeight: 400,
          }}
        >
          Mark all as read
        </button>
      </div>
      {notifications?.map((notification: NotificationType) => (
        <div
          key={notification.notificationId}
          style={{
            borderBottom: "1px solid #ECECEC",
            paddingBottom: "10px",
            marginBottom: "10px",
            padding: "16px",
            borderRadius: "8px",
            backgroundColor:
              hoveredNotification === notification.notificationId
                ? "#b5babf"
                : !!!notification?.seen
                  ? "#b9b6ad"
                  : "#fff",
            cursor: "pointer",
          }}
          onMouseEnter={() =>
            setHoveredNotification(notification.notificationId)
          }
          onMouseLeave={() => setHoveredNotification(null)}
          onClick={() => {
            handlePress(notification);
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <span
              style={{
                marginRight: "10px",
                color: "#F26533",
                fontSize: "20px",
              }}
            >
              <PendingNotificationIcon />
            </span>
            <AText fontSize={"16px"} fontWeight={600} color={color.DARK}>
              {notification.title}
            </AText>
          </div>

          <AText fontSize={"16px"} fontWeight={400} color={color.DARK}>
            {notification.body}
          </AText>
        </div>
      ))}
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        {notificationsHasNextPage && (
          <AButton onClick={handleLoadMore} loading={isLoading}>
            Load More ↓
          </AButton>
        )}
      </div>
    </>
  );
};

export default Notifications;
