import {
  NotificationMessage,
  NotificationTagsParams,
} from "notifications/presentation/store/types";
import * as INotificationRepository from "../business/INotificationRepository";
import { injectable, provided } from "inversify-sugar";

@injectable()
export default class NotificationUseCase {
  @provided(INotificationRepository.INotificationRepositoryToken)
  private notificationRepository!: INotificationRepository.INotificationRepository;

  public getNotificationDay() {
    return this.notificationRepository.getNotificationDay();
  }

  public updateNotificationDay(value: any) {
    return this.notificationRepository.updateNotificationDay(value);
  }
  public getNotificationTags(params: NotificationTagsParams) {
    return this.notificationRepository.getNotificationTags(params);
  }

  public createNotificationMessage(params: NotificationMessage) {
    return this.notificationRepository.createNotificationMessage(params);
  }

  public getNotification(page: number) {
    return this.notificationRepository.getNotification(page);
  }

  public seenNotification(id: number) {
    return this.notificationRepository.seenNotification(id);
  }

  public seenAllNotification() {
    return this.notificationRepository.seenAllNotification();
  }
}
