import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "core/presentation/store";
import { notificationModuleContainer } from "notifications/NotificationModule";
import NotificationUseCase from "../../application/NotificationUseCase";
import {
  NotificationMessage,
  NotificationTagsParams,
  NotificationType,
} from "./types";

const initialState: any = {
  notificationTags: {
    shipper: [],
    ["vehicle owner"]: [],
    driver: [],
  },
  notifications: [],
  notificationDay: undefined,
};

export const getNotificationDayThunk = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("notification/getNotificationDay", async (params: any) => {
  const useCase = notificationModuleContainer.getProvided(NotificationUseCase);
  const response = useCase.getNotificationDay();
  return response;
});

export const updateNotificationDayThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("notification/updateNotificationDay", async (value: any) => {
  const useCase = notificationModuleContainer.getProvided(NotificationUseCase);
  const response = await useCase.updateNotificationDay(value);
  return response;
});

export const getNotificationTagsThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>(
  "notification/getNotificationTags",
  async (params: NotificationTagsParams) => {
    const useCase =
      notificationModuleContainer.getProvided(NotificationUseCase);
    const response = useCase.getNotificationTags(params);
    return response;
  }
);

export const createNotificationMessageThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>(
  "notification/createNotificationMessage",
  async (values: NotificationMessage) => {
    const useCase =
      notificationModuleContainer.getProvided(NotificationUseCase);
    const response = useCase.createNotificationMessage(values);
    return response;
  }
);

export const getNotificationThunk = createAsyncThunk<any, any>(
  "notification/get-notification",
  async ({ page }) => {
    const useCase =
      notificationModuleContainer.getProvided(NotificationUseCase);
    return await useCase.getNotification(page);
  }
);

export const seenNotificationThunk = createAsyncThunk<any, { id: number }>(
  "notification/seen-notification",
  async ({ id }: { id: number }) => {
    const useCase =
      notificationModuleContainer.getProvided(NotificationUseCase);
    return await useCase.seenNotification(id);
  }
);

export const seenAllNotificationThunk = createAsyncThunk<any>(
  "notification/seen-all-notification",
  async () => {
    const useCase =
      notificationModuleContainer.getProvided(NotificationUseCase);
    return await useCase.seenAllNotification();
  }
);

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getNotificationDayThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getNotificationDayThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.notificationDay = action.payload.data.data;
      })
      .addCase(getNotificationDayThunk.rejected, (state, action) => {
        state.isLoading = false;
        console.error(action.error.message || "Unknown error");
      });
    builder
      .addCase(updateNotificationDayThunk.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateNotificationDayThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.notificationDay = action.payload.data.data;
      })
      .addCase(updateNotificationDayThunk.rejected, (state, action) => {
        state.isLoading = false;
        console.error(action.error.message || "Unknown error");
      });

    builder
      .addCase(getNotificationTagsThunk.fulfilled, (state, action) => {
        const type = action.meta.arg.type;
        state.isLoading = false;
        state.error = null;
        state.notificationTags = {
          [type]: [
            state.notificationTags[type] && {
              ...state.notificationTags?.[type],
            },
            action.payload.data.data,
          ],
        };
      })
      .addCase(getNotificationTagsThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      });

    builder.addCase(getNotificationThunk.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getNotificationThunk.fulfilled, (state, action) => {
      state.notifications = [
        ...state.notifications,
        ...action?.payload?.data.allNotification,
      ];
      state.notificationsCount = action?.payload?.data.count;
      state.notificationsHasNextPage = action?.payload?.data.hasNextPage;
      state.isLoading = false;
    });
    builder.addCase(getNotificationThunk.rejected, (state, action) => {
      console.error(action.error.message || "unknown error");
      state.isLoading = false;
    });

    builder.addCase(seenNotificationThunk.fulfilled, (state, action) => {
      // state.notifications = action?.payload?.data;

      const updatedNotification: NotificationType = action.payload.data;

      // Find the index of the existing notification in the array
      const index = state.notifications.findIndex(
        (notification: NotificationType) =>
          notification.notificationId === updatedNotification.notificationId
      );

      // If the notification is found, update its information
      if (index !== -1) {
        state.notifications[index] = updatedNotification;
      }
    });
    builder.addCase(seenNotificationThunk.rejected, (state, action) => {
      console.error(action.error.message || "unknown error");
    });

    builder.addCase(seenAllNotificationThunk.fulfilled, (state, action) => {
      state.notifications = action?.payload?.data;
    });
    builder.addCase(seenAllNotificationThunk.rejected, (state, action) => {
      console.error(action.error.message || "unknown error");
    });
  },
});

export const userSelectors = {};

export const notificationActions = notificationSlice.actions;

export const notificationThunks = {
  getNotificationDayThunk,
  updateNotificationDayThunk,
  getNotificationTagsThunk,
  createNotificationMessageThunk,
  getNotificationThunk,
  seenNotificationThunk,
  seenAllNotificationThunk,
};

export const notificationReducer = notificationSlice.reducer;
