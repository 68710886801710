import { INotificationRepository } from "../business/INotificationRepository";
import ApiService from "../../core/presentation/services/ApiService";
import { injectable } from "inversify-sugar";
import {
  NotificationMessage,
  NotificationTagsParams,
  NotificationType,
} from "notifications/presentation/store/types";

@injectable()
class NotificationRepository implements INotificationRepository {
  public async getNotificationDay(): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        `/vehicle/document-expiry-day`,
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async updateNotificationDay(value: NotificationMessage): Promise<any> {
    try {
      const response: any = await ApiService.setPost(
        `/vehicle/document-expiry-day`,
        value
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getNotificationTags(
    params: NotificationTagsParams
  ): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        `/notification/tags?type=${params.type}&title=${params.title}`,
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async createNotificationMessage(
    value: NotificationMessage
  ): Promise<any> {
    try {
      const response: any = await ApiService.setPost(
        `/notification/message`,
        value
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getNotification(page: number): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        page > 0 ? `notification/v2?page=${page}&limit=10` : "notification/v2",
        ""
      );

      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async seenNotification(id: number): Promise<NotificationType> {
    try {
      const response: any = await ApiService.updatePost(
        "notification/seen/" + id,
        ""
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async seenAllNotification(): Promise<any> {
    try {
      const response: any = await ApiService.updatePost(
        "notification/seen/all",
        ""
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default NotificationRepository;
