import { lazy } from "react";

const fleetRoutes = [
  {
    path: "/vehicle-category",
    Component: lazy(() => import("../pages/VehicleCategory")),
    permission: ["vehicle_category:list_all"],
  },
  {
    path: "/vehicle-category/create",
    Component: lazy(
      () => import("../components/VehicleCategory/CreateEditVehicleCategory")
    ),
    permission: ["vehicle_category:create"],
  },
  {
    path: "/vehicle-category/edit/:id",
    Component: lazy(
      () => import("../components/VehicleCategory/EditVehicleCategory")
    ),
    permission: ["vehicle_category:update"],
  },
  {
    path: "/vehicle-category/details/:id",
    Component: lazy(
      () => import("../components/VehicleCategory/DetailVehicleCategory")
    ),
    permission: ["vehicle_category:detail"],
  },

  {
    path: "/vehicle-type",
    Component: lazy(() => import("../pages/VehicleType")),
    permission: ["vehicle_category:list_all"],
  },
  {
    path: "/vehicle-type/create",
    Component: lazy(
      () => import("../components/VehicleType/CreateEditVehicleType")
    ),
    permission: ["vehicle_category:create"],
  },
  {
    path: "/vehicle-type/details/:id",
    Component: lazy(
      () => import("../components/VehicleType/DetailVehicleType")
    ),
    permission: ["vehicle_category:detail"],
  },
  {
    path: "/vehicle/create",
    Component: lazy(
      () => import("../components/Vehicles/createEdit/CreateVehicles")
    ),
    permission: ["vehicle:create"],
  },
  {
    path: "/vehicle/edit/:id",
    Component: lazy(
      () => import("../components/Vehicles/createEdit/EditVehicles")
    ),
    permission: ["vehicle:update"],
  },
  {
    path: "/vehicle/details/:id",
    Component: lazy(() => import("../components/Vehicles/details")),
    permission: ["vehicle:detail"],
  },
  {
    path: "/vehicle",
    Component: lazy(() => import("../pages/Vehicle")),
    permission: ["vehicle:list", "vehicle:list_all"],
  },
  {
    path: "/trip",
    Component: lazy(() => import("../pages/Trips/index")),
    permission: ["trip:list_all", "trip:list"],
  },
  {
    path: "/completed-trip",
    Component: lazy(() => import("../pages/CompletedTrips/index")),
    permission: ["trip:list_all", "trip:list"],
  },
  {
    path: "/trip/create",
    Component: lazy(() => import("../components/Trips/createEdit/createTrip")),
    permission: ["trip:create"],
  },
  {
    path: "/trip/edit/:id",
    Component: lazy(() => import("../components/Trips/edit")),
    permission: ["trip:update"],
  },
  {
    path: "/trip/details/:id",
    Component: lazy(() => import("../components/Trips/details")),
    permission: ["trip:detail"],
  },
  {
    path: "/orders",
    Component: lazy(() => import("../pages/OrderAndBids")),
    permission: ["bid_request:detail"],
  },
  {
    path: "/vehicle-shift-requests",
    Component: lazy(() => import("../pages/VehicleShiftRequests")),
    permission: ["vehicle_shift:list_all"],
  },
  {
    path: "/vehicle-shift-requests/details/:id",
    Component: lazy(
      () =>
        import(
          "../components/VehicleShiftRequests/details/VehicleShiftRequestDetails"
        )
    ),
    permission: ["vehicle_shift:detail"],
  },
  {
    path: "*",
    Component: lazy(() => import("../../../core/presentation/pages/NotFound")),
    permission: ["*"],
  },
];

export default fleetRoutes;
