export default {
  core: {
    errors: {
      screenNotFound: "Screen not found",
    },
    pages: {
      NotFound: {
        pageNotExist: "Sorry ! , The Page you are looking for does not exist",
      },
    },
    components: {
      SignUpLayout: {
        WelcometoLODBOD: "Welcome to LODBOD",
        WelcomeSubtext:
          "LODBOD is a transportation management system designed to streamline process of connecting shippers with vehicle owners",
      },
      Upload: {
        DragDropFilesOrClickToUpload:
          "Drag and drop files here or click to upload",
        ClicktoUpload: "Click to Upload",
      },
    },
  },
  post: {
    pages: {
      Posts: {
        loading: "Loading...",
      },
      Post: {
        loading: "Loading...",
      },
    },
  },
  user: {
    components: {
      platformAdmin: {
        create: {
          CreatePlatformAdmin: "Create Users",
          Firstname: "First Name",
          Middlename: "Middle Name",
          Lastname: "Last Name",
          Email: "Email",
          ContactNumber: "Contact Number",
          CreateRoles: "Create Roles",
          Roles: "Roles",
        },
        list: {
          Filter: "Filter",
          Name: "Name",
          Email: "Email",
          ContactNumber: "Contact Number",
          PlatformAdminList: "Platform Admin List",
        },
        reports: {
          VehicleOwnerReport: "Vehicle Owner Report",
          Source: "Source",
          LoginStatus: "Login Status",
          DriverReport: "Driver Report",
          DriverName: "Driver's Name",
          Address: "Address",
          VehicleOwnerName: "Vehicle Owner's Name ",
          VehicleReport: "Vehicle Report",
          VehicleChecklistReport: "Vehicle Checklist Report",
          VehicleNumber: "Vehicle Number",
          VehicleType: "Vehicle Type",
          VehicleOwner: "Vehicle Owner",
          VehicleDriver: "Vehicle Driver",
          Sthaniya: "Sthaniya",
          Status: "Status",
          VehicleLogHistoryReport: "Vehicle Log History Report",
          GPSReport: "GPS Report",
          DeclinedVehicleReport: "Declined Vehicle Report",
          EditRequestVehicleReport: "Edit Request Vehicle Report",
          AwatingApprovalVehicleReport: "Awaiting Approval Vehicle Report",
          LicenseNumber: "License Number",
          AssignedVehicle: "Assigned Vehicle",
        },
        PlatformAdmin: "Platform Admin",
        CreateNewPlatformAdmin: "Create New Users",
        CreateNewRoles: "Create New Roles",
      },
      vehicleOwner: {
        vehicleOwner: "Vehicle Owner",
        CreateNewVehicleOwner: "Create New Vehicle Owner",
        TrackManageandCreateVehicleOwners:
          "Track, manage and create vehicle drivers.",
        create: {
          CreateVehicleOwner: "Create Vehicle Owner",
          Firstname: "First Name",
          Middlename: "Middle Name",
          Lastname: "Last Name",
          Email: "Email",
          ContactNumber: "Contact Number",
        },
        list: {
          Filter: "Filter",
          Name: "Name",
          Email: "Email",
          ContactNumber: "Contact Number",
          VehicleOwnerList: "Vehicle Owner List",
        },
      },
    },
  },
  auth: {
    pages: {
      Login: {
        LoginText: "Login",
        LoginSubText: "Please enter your details.",
        Username: "Username",
        EnterUsername: "Enter Username",
        UsernamenotregisteredPleaseverify:
          "Username not registered. Please verify",
        Continue: "Continue",
        DontHaveAccount: "Don't have an Account?",
        RegisterNow: "Register Now",
        ForgotPassword: "Forgot Password",
        Next: "Next",
        PasswordReset: "Password Reset",
      },
      Register: {
        Register: "Register",
        RegisterSubText: "Register as a shipper with your details below.",
        CreateAccount: "Create an Account",
        Firstname: "First Name",
        Middlename: "Middle Name",
        Lastname: "Last Name",
        Email: "Email",
        ContactNumber: "Contact Number",
        Designation: "Designation",
        CompanyName: "Company Name",
        SelectUserType: "Select User Type",
        Individual: "Individual",
        Company: "Company",
        CPassword: "Confirm Password",
        AlreadyHaveAccount: "Already have an account ?",
      },
      VerifyNumber: {
        VerificationCode: "Verification Code",
        Verify: "Verify",
        EnterYourOTP: "Enter your OTP",
        OTPSentToNumber: "OTP code has been sent to your mobile number.",
      },
      VerifyEmail: {
        OTPSentToEmail: "OTP code has been sent to your email.",
      },
      VerifyUsername: {
        OTPSentToUsername: "OTP code has been sent to your username.",
      },

      TermsandConditions: {
        TermsandConditions: "Terms & Conditions",
        lastRevised: "Last Revised: December 16, 2013",
        welcomeMessage: `Welcome to LodBod Application (the "App"). These Terms and Conditions govern your use of the App. By using the App, you agree to comply with and be bound by these Terms and Conditions. If you do not agree with these terms, please do not use the App.`,
        agreementFirstLine: "1. User Accounts:",
        agreementFirstLine_First: "1.1 Registration:",
        agreementFirstLine_FirstDescription:
          "To use certain features of the App, you may be required to create a user account. You agree to provide accurate, current, and complete information during the registration process.",
        agreementFirstLine_Second: "1.2 Account Security:",
        agreementFirstLine_SecondDescription:
          "You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. Notify us immediately if you become aware of any unauthorized use of your account.",
        agreementSecondLine: "2. App Usage:",
        agreementSecondLine_First: "2.1 Load Information:",
        agreementSecondLine_FirstDescription:
          "Users may submit information about available loads or carrier services. You agree to provide accurate and up-to-date information, and you are solely responsible for the accuracy of the information you submit.",
        agreementSecondLine_Second: "2.2 Communication:",
        agreementSecondLine_SecondDescription:
          "The App facilitates communication between users for load matching. You agree to use the communication features of the App responsibly and in compliance with applicable laws.",
        agreementSecondLine_Third: "2.3 Prohibited Activities:",
        agreementSecondLine_ThirdDescription:
          "You agree not to engage in any prohibited activities, including but not limited to fraud, impersonation, unauthorized access, or any activity that violates these Terms and Conditions.",
        agreementThirdLine: "3. Pricing:",
        agreementThirdLine_First: "3.1 Subscription Fees:",
        agreementThirdLine_FirstDescription:
          "Certain features of the App may require payment of subscription fees. By using these features, you agree to pay the applicable fees as described in the pricing section of the App.",
        agreementThirdLine_Second: "3.2 Fee for facilitating bid matching",
        agreementThirdLine_SecondDescription:
          "3.2.1 In consideration for the use of the bid matching platform, you acknowledge and agree that the App charges a service fee in addition to 3.1 above for facilitating bid matching and related services.",
        agreementThirdLine_SecondDescription_Second:
          "3.2.1 In consideration for the use of the bid matching platform, you acknowledge and agree that the App charges a service fee in addition to 3.1 above for facilitating bid matching and related services.",
        agreementThirdLine_Third: "3.3 Payment Terms:",
        agreementThirdLine_ThirdDescription:
          "Failure to pay the service fee in accordance with the established terms may result in the suspension or termination of your account. Payments are due in advance and is non-refundable, except as otherwise expressly stated in the App's refund policy. Failure to pay fees may result in the suspension or termination of your account.",
        agreementThirdLine_Fourth: "3.4 Changes to Pricing:",
        agreementThirdLine_TFourthDescription:
          "The App reserves the right to change the pricing for subscription fees at any time. Changes to pricing will be effective upon notice to you.",
        agreementThirdLine_Fifth: "3.5 Billing and Invoicing:",
        agreementThirdLine_FifthDescription:
          "The App will provide carriers with a clear and transparent breakdown of the service fees associated with each transaction. Invoices, if applicable, will include relevant details and payment instructions.",
        agreementThirdLine_Sixth: "3.6 Value Added Taxes:",
        agreementThirdLine_SixthDescription:
          "You are responsible for any applicable VAT associated with the service fee. The App may collect and remit VAT as required by applicable laws.",
        agreementThirdLine_Seven: "3.7 Adjustment to Service Fee:",
        agreementThirdLine_SevenDescription:
          "The App reserves the right to adjust the service fee structure, and any changes will be communicated to you in advance. Continued use of the App following such changes constitutes acceptance of the modified service fee terms.",
        agreementFourthLine: "4. Bidding:",
        agreementFourthLine_First: "4.1 Submission of Bids:",
        agreementFourthLine_FirstDescription:
          "You using the App may submit price bids in response to shipping demands raised by shippers. By submitting a bid, you agrees to provide accurate and binding pricing information for the specified transportation services.",
        agreementFourthLine_Second: "4.2 Bid Validity:",
        agreementFourthLine_SecondDescription:
          "You acknowledge that submitted bids are valid for the specified time frame set by the shipper or as otherwise communicated within the App.",
        agreementFourthLine_Third: "4.3 Acceptance by Shipper:",
        agreementFourthLine_ThirdDescription:
          "The App facilitates the submission and display of your bids to shippers, but it does not guarantee that a shipper will accept any particular bid. Shippers retain the right to evaluate and choose bids based on their own criteria.",
        agreementFourthLine_Fourth: "4.4 Use of Your Information:",
        agreementFourthLine_FourthDescription:
          "By submitting a bid, you grant the app the right to share relevant information, including your details and bid specifics, with the shipper for the purpose of facilitating the bid evaluation and acceptance process.",
        agreementFourthLine_Fifth: "4.5 Non-Guarantee of Acceptance:",
        agreementFourthLine_FifthDescription:
          "The App does not guarantee that the shipper will accept any bid submitted by you. The decision to accept or reject a bid is at the sole discretion of the shipper.",
        agreementFourthLine_Sixth: "4.6 Communication of Bid Status:",
        agreementFourthLine_SixthDescription:
          "The App will notify you of the status of your bids, including whether a bid has been accepted, rejected, or remains pending. You are responsible for monitoring bid status within the App.",
        agreementFourthLine_Seventh: "4.7 Revision or Withdrawal of Bids:",
        agreementFourthLine_SeventhDescription:
          "You may have the option to revise or withdraw bids within the specified time frame set by the shipper or as otherwise communicated within the App.",
        agreementFourthLine_Eighth: "4.8 Binding Nature of Accepted Bids:",
        agreementFourthLine_EighthDescription:
          "If a shipper accepts your bid, the bid becomes a binding agreement between the carrier and shipper, subject to the terms and conditions outlined in the App.",
        agreementFourthLine_Ninth: "4.9 Payment Terms:",
        agreementFourthLine_NinthDescription:
          "The payment terms for accepted bids will be determined between you and the shipper as agreed upon in the bid acceptance process. The App is not involved in the negotiation or enforcement of payment terms.",
        agreementFourthLine_Tenth: "4.10 Dispute Resolution:",
        agreementFourthLine_TenthDescription:
          "Disputes arising from the bidding and acceptance process are to be resolved directly between you and the shipper. The App is not responsible for disputes related to freight price, bid acceptance, or any other aspect of the transaction.",

        agreementFifthLine: "5. Content Ownership:",
        agreementFifthLine_First: "5.1 User-Generated Content:",
        agreementFifthLine_FirstDescription:
          "Users retain ownership of the content they submit to the App. By submitting content, you grant us a non-exclusive, royalty-free, worldwide license to use, reproduce, modify, and display the content in connection with the App.",
        agreementFifthLine_Second: "5.2 Intellectual Property:",
        agreementFifthLine_SecondDescription:
          "The App and its content are protected by intellectual property laws. You agree not to reproduce, distribute, modify, or create derivative works based on the App without our prior written consent.",

        agreementSixthLine: "6. Termination:",
        agreementSixthLine_First: "6.1 Termination by Users:",
        agreementSixthLine_FirstDescription:
          "You may terminate your account at any time by following the account termination process within the App.",
        agreementSixthLine_Second: "6.2 Termination by Us:",
        agreementSixthLine_SecondDescription:
          "We reserve the right to suspend or terminate your account and access to the App if you violate these Terms and Conditions or engage in fraudulent or illegal activities.",

        agreementSeventhLine: "7. Privacy:",
        agreementSeventhLine_Description_First:
          "Your use of the App is also governed by our Privacy Policy, which can be found at",
        agreementSeventhLine_Description_Second:
          " By using the App, you consent to the terms of the Privacy Policy.",

        agreementEighthLine: "8. Changes to Terms and Conditions:",
        agreementEighthLine_Description:
          "We reserve the right to update these Terms and Conditions from time to time. We will notify you of any changes by posting the updated Terms and Conditions on this page.",

        agreementNinthLine: "9. Contact Us:",
        agreementNinthLine_Description:
          "If you have any questions or concerns about these Terms and Conditions, please contact us at support@lodbod.com.",

        agreementAcceptanceLine:
          "By using the App, you agree to comply with and be bound by these Terms and Conditions.",
        Accept: "Accept",
        Decline: "Decline",
      },
      LoginMethod: {
        Password: "Password",
        setNewPassword: "Set a new password.",
        CPassword: "Confirm Password",
        passwordDontMatch: "Passwords do not match",
        confirm: "Confirm",
      },
      PasswordReset: {
        NewPassword: "New Password",
        CurrentPassword: "Current Password",
        CPassword: "Confirm New Password",
        passwordDontMatch: "Passwords do not match",
        confirm: "Confirm",
        update: "Update",
      },
      Dashboard: {
        Welcome: "Welcome,",
        Shipper: "Shipper",
        Today: "Today",
        Profile: "Profile",
        Logout: "Logout",
      },
      Profile: {
        Profile: "Profile",
        Edit: "Edit",
        Citizenshiip: "Citizenship",
        License: "License",
        ChangePassword: "Change Passowrd",
        Tutorials: "Tutorials",
        HelpAndSupport: "Help & Support",
        Logout: "Logout",
      },
      AdditionalInformation: {
        ProvinceNo: "Province No.",
        Pradesh: "Pradesh",
        AdditionalInformationRequired: "Additional Information Required",
        AdditionalInformationSubText:
          "Please fill up the additional information in order to create an order.",
        CompanySetup: "Company Setup",
        CompanyDocuments: "Company Documents",
        PersonalSetup: "Personal Setup",
        Firstname: "First Name",
        Middlename: "Middle Name",
        Lastname: "Last Name",
        Email: "Email Address",
        ContactNumber: "Contact Number",
        Designation: "Designation",
        ShipperType: "Shipper Type",
        ShipperCategory: "Shipper Category",
        CompanyName: "Company Name",
        Guest: "Guest",
        Contractor: "Contractor",
        Individual: "Individual",
        Company: "Company",
        State: "State",
        District: "District",
        LocalLevel: "Local Level",
        StreetAddress: "Street Address",
        PANNumber: "PAN Number",
        PANDocument: "PAN Document",
        BusinessDetails: "Business Details",
        BusinessType: "Business Type",
        Typesofgoods: "Types of goods",
        CompanyContactNumber: "Company Contact Number",
        RegistrationNumber: "Registration Number",
        HowOftenDoYouShip: "How often do you ship?",
        CompanyLogo: "Company Logo",
        PANVATDocument: "PAN/VAT Document",
        RegistrationDocument: "Registration Document",
        Skip: "Skip",
        Cancel: "Cancel",
        Next: "Next",
        GoBack: "Go Back",
        Continue: "Continue",
        Name: "Name",
        Category: "Category",
        PickupAdress: "Pickup Address",
        DeliveryAddress: "Delivery Address",
        SelectonMap: "Pin on Map",
        MostRegularTradeLines: "Most Regular Trade Lines",
        From: "From",
        To: "To",
        AddLocation: "Add Location",
        GetCurrentLocation: "Get Current Location",
        setupCheckbox: "Setup Pickup Address, Party and Regular Trade Lines",
        Update: "Update",
      },
      LoginWitPassword: {
        Password: "Password",
        EnterPassword: "Enter Password",
        Submit: "Submit",
      },
      ForgetPassword: {
        ForgetPassword: "Forget Password ?",
        ForgetPasswordTitle: "Set Password ",
        Password: "Password",
        ConfirmPassword: "Confirm Password",
        Submit: "Submit",
        LogInWithPassword: "Login with Password",
        AlreadyHavePassword: "Already Have Password ",
      },
      EditProfile: {
        EditProfile: "Edit Profile",
      },
    },
  },
  fleet: {
    pages: {
      Driver: {
        Skip: "Skip",
        Next: "Next",
        Update: "Update",
        EditDriver: "Edit Driver",
      },
      DriverDetails: {
        GoBack: "Go Back",
        Details: "Details",
        Documents: "Documents",
        DrivingLicense: "Driving License",
        CitizenshipFront: "Citizenship Front",
        CitizenshipBack: "Citizenship Back",
      },
    },
  },
  notification: {
    driver: {
      notificationBeforeDays: "Send Notification before days",
    },
  },
  failedModalTexts: {
    AccountNotRegistered: "Account not registered",
    IncorrectUsernameSub:
      "The username you entered does not match our records. Please ensure you have entered the correct contact number/email.",
    TryAgain: "Try Again",
    IncorrectPassword: "Incorrect Password",
    IncorrectPasswordSub:
      "The password you entered is incorrect. Please double-check your password and try again.",
    AccountLocked: "Account Locked",
    Ok: "Ok",
    VerificationFailed: "Verification Failed",
    VerificationFailedSub: "Please ensure that you entered the correct OTP.",
    AdditionalInformationFailed: "Additional Information Failed",
    AdditionalInformationFailedSub:
      "To proceed with your order, you must provide the additional information.",
    TermsandConditionsDeclined: "Terms and Conditions Declined",
    TermsandConditionsDeclinedSub:
      "We're sorry, but you must accept our Terms and Conditions to proceed.",
    DriverEditFailed: "Driver Update  Failed",
    DriverEditFailedSub: "Driver Updated Failed",
  },
  successModalTexts: {
    AccountRegisteredSuccessful: "Account Registered Successfully",
    DriverEditSuccess: "Driver Updated",
    DriverEditSuccessSub: "Driver Updated successfully",
    VehicleOwnerEditSuccess: "Vehicle Owner Updated",
    VehicleOwnerEditSuccessSub: "Vehicle  Updated successfully",
    ShipperEditSuccess: "Shipper Updated",
    ShipperEditSuccessSub: "Shipper Updated successfully",
    PlatformAdminCreatedSuccessfully: "Users Created Successfully",
    PlatformAdminCreatedSuccessfullySub:
      "The users has been created and has been sent the unique application link to continue the account creation process.",
    AccountRegisteredSub: "Please check your email for further verification",
    Continue: "Continue",
    VerificationSuccessful: "Verification Successful",
    ok: "Ok",
    PasswordChangedSuccessful: "Password Changed Successful",
    PasswordChanged: "Password Changed",
    PasswordChangedSub: "Your password has been changed successfully",
    PasswordChangedSuccessfulSub:
      "Congratulations! You have set a new password. Please login to continue",
    VerificationEmailSuccessfulSub:
      "Congratulations! Your email has been successfully verified.",
    VerificationMobileSuccessfulSub:
      "Congratulations! Your mobile number has been successfully verified.",
    AdditionalInformationSuccess: "Additional Information Completed",
    AdditionalInformationSuccessSub:
      "We have received your additional information. We will get in touch soon after verifying this information.",
  },
};
